<template>
  <div class="exam_statistics bShadow" style="padding-top: 10px">
    <div class="exam_style yt-flex-layout">
      <p>正在进行的考试</p>
      <a @click="toPath({ route: 'newExamList', params: 2 })">更多>></a>
    </div>

    <div class="exam_content" v-if="examList.length">
      <div
        class="exam_list"
        v-for="(e, eIndex) in examList.filter(a => {
          return a.startTime || a.createdTime
        })"
        :key="eIndex"
      >
        <p class="first_p yt-flex-layout">
          <span class="yt-flex-layout"
            ><span class="yt-flex-layout" style="align-items: center"><span class="circle_style" />{{ e.name }}</span>
            <span class="type-span__style" :style="{ background: e.external ? '#FFCC3E' : '#448bff', color: '#fff' }">{{
              e.external ? '外部招聘' : '内部评测'
            }}</span></span
          >
          <span
            >已开始<span :style="{ color: '#448BFF', marginLeft: '5px', fontWeight: 'bold' }">{{
              calculateTheTimeDifference(e.startTime || e.createdTime)
            }}</span></span
          >
        </p>
        <p class="second_p yt-flex-layout">
          {{ $formatTime(e.startTime, 'yyyy-MM-dd') }} 至
          {{ $formatTime(e.endTime, 'yyyy-MM-dd') }}
        </p>
        <p class="yt-flex-layout">考试总分：{{ e.total || 0 }}分 ({{ e.questionAmount || 0 }}道题)</p>
        <p class="third_p yt-flex-layout">
          <span>考生人数：{{ e.examineeNum || 0 }}人</span>
          <a @click="$router.push({ name: 'monitorDetail', params: { examId: e.examId } })">监考</a>
        </p>
      </div>
    </div>

    <div class="not_data yt-flex-layout" v-else>
      <img width="103" height="88" src="../../../assets/console/not_exam.png" alt="" />
      <span>没有正在进行中的考试。</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExamNowing',
  data() {
    return {
      countTime: '00 : 00',
      examList: []
    }
  },
  methods: {
    toPath(row) {
      this.$parent.loading = true
      this.$parent.examLoading = true
      if (row.route) {
        this.$router.push({
          name: row.route,
          params:
            row.params === undefined
              ? null
              : {
                  status: row.params
                }
        })
      }
      this.$parent.loading = false
      this.$parent.examLoading = false
    },
    calculateTheTimeDifference(time) {
      if (!time) return '00 : 00'
      // 当前时间
      let nDates = new Date().getTime()
      // 计算时间的毫秒差
      let timeDifference = nDates - time
      //相差的天数
      let days = Math.floor(timeDifference / (24 * 3600 * 1000))
      //小时数
      let leave = timeDifference % (24 * 3600 * 1000) //计算天数剩下的毫秒数
      let hours = Math.floor(leave / (3600 * 1000))
      //分钟数
      let min = Math.floor((leave % (3600 * 1000)) / (3600 * 1000))
      let nDate = Math.floor(new Date().getTime() / 1000)
      let sTime = nDate - time / 1000
      let h = Math.floor(sTime / 3600)
      let m = Math.floor((sTime / 60) % 60)
      if (days) {
        return `${days ? `${days}` : ''} 天 ${hours < 10 ? `0${hours}` : `${hours}`}  时 ${min < 10 ? `0${min}` : `${min}`} 分`
      } else {
        return `${h < 10 ? `0${h}` : `${h}`}  时 ${m < 10 ? `0${m}` : `${m}`} 分`
      }
    },
    getData(arr) {
      if (arr.length) {
        this.examList = arr
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/mixin';
@import '~@/theme/variables';
.exam_statistics {
  background: #fff;
  padding: 0 20px;
  margin-bottom: 20px;
  .exam_style {
    height: 44px;
    align-items: center;
    justify-content: space-between;
    p {
      .font(16px, bold, #000);
    }
    a {
      color: #448bff;
    }
  }
  .exam_content {
    padding-bottom: 30px;
    .font(16px, Regular, #000);
    display: grid;
    row-gap: 21px;
    column-gap: 27px;
    grid-template-columns: 1fr 1fr;
    .exam_list {
      border-radius: 4px;
      padding: 10px 20px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
      .first_p {
        justify-content: space-between;
      }
      .second_p {
        .font(12px, Regular, #666666);
        margin: 12px 0 24px 0;
      }
      .third_p {
        margin-top: 12px;
        a {
          margin-left: 12px;
          color: #448bff;
        }
      }
      .circle_style {
        .wh(7px, 7px);
        margin-right: 6px;
        border-radius: 50%;
        background: #448bff;
      }
    }
  }
  .not_data {
    justify-content: center;
    align-items: center;
    min-height: 350px;
    img {
      margin-right: 15px;
    }
    span {
      color: #666;
    }
  }
}
.type-span__style {
  .font(12px, Regular, #fff);
  border-radius: 13px;
  padding: 5px 15px;
  margin-left: 12px;
}
</style>
