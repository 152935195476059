<template>
  <div class="examine_data bShadow">
    <div class="examine_title yt-flex-layout">考生数据分析</div>
    <div class="examine_chart">
      <!--      <el-select class="select__style" v-model="value" style="width: 222px">-->
      <!--        <el-option :value="0" label="近七日考生数据分析" />-->
      <!--        <el-option :value="1" label="近三十日考生数据分析" />-->
      <!--      </el-select>-->
      <el-date-picker
        class="select__style"
        :picker-options="pickerOptions"
        v-model="value"
        type="daterange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        :default-value="[new Date() - 3600 * 1000 * 24 * 7, new Date()]"
      >
      </el-date-picker>
      <div id="examinCharts" class="charts__style" />
    </div>
  </div>
</template>

<script>
import { options } from '@components/manage/console/commonJS'

export default {
  name: 'Echarts',
  data() {
    return {
      value: [],
      chart: null,
      chartLoading: false,
      chartOptions: options,
      pickerOptions: {
        disabledDate(time) {
          // return time.getTime() < Date.now() // 选当前时间之后的时间
          return time.getTime() > Date.now() // 选当前时间之前的时间
        }
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        this.$parent.chartData(val ? { endTime: new Date(val[1]), startTime: new Date(val[0]) } : null)
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    getData(arr) {
      if (arr.length) {
        // 先初始化数据
        this.chartOptions.xAxis.data = []
        this.chartOptions.series.forEach(cs => {
          cs.data = []
        })

        // 整理真实数据
        arr.forEach((a, index) => {
          this.chartOptions.xAxis.data.push(a.date)
          this.chartOptions.series.forEach(cs => {
            cs.data.push(a[cs.key])
          })
        })
        this.initData()
      }
    },
    reStart() {
      if (this.chart) {
        this.chart.resize()
      }
    },
    initData() {
      this.chart = this.$echarts.init(document.getElementById('examinCharts'))
      this.chart.setOption(this.chartOptions)
      this.$parent.chartLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
@chartHeight: 550px;
.examine_data {
  padding: 20px 50px 30px 30px;
  margin-bottom: 20px;
  background: #fff;
  min-height: @chartHeight;
  .examine_title {
    .font(16px, bold, #000);
  }
  .examine_chart {
    position: relative;
    height: calc(@chartHeight - 50px);
    .select__style {
      position: absolute;
      top: 0;
      z-index: 999;
      right: 50px;
      ::v-deep {
        .el-input__inner {
          border: 1px solid #c9c9c9;
        }
      }
    }
    .charts__style {
      .wh(100%, 90%);
    }
  }
}
</style>
