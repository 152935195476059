const options = {
  color: ['#448BFF'],
  title: {
    text: '折线图堆叠',
    show: false
  },
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    data: ['考试人数']
  },
  grid: {
    top: '20%',
    left: '3%',
    right: '4%',
    bottom: '10%'
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: {
    type: 'value',
    show: false
  },
  series: [
    {
      name: '考试人数',
      key: 'examineeQuantity',
      type: 'line',
      smooth: true,
      data: []
    }
  ]
}
const countList = [
  {
    title: '考试总数',
    key: 'examQuantity',
    icon: require('@/assets/console/count_1.png'),
    route: 'newExamList',
    shadow: '0px 4px 3px rgba(0, 112, 197, 0.16)',
    shadowHover: '0px 4px 3px rgba(0, 112, 197, 0.26)',
    statisticsCount: 869
  },
  {
    title: '考题总数',
    key: 'questionQuantity',
    icon: require('@/assets/console/count_2.png'),
    route: 'questionList',
    shadow: '0px 4px 3px rgba(197, 0, 158, 0.16)',
    shadowHover: '0px 4px 3px rgba(197, 0, 158, 0.26)',
    statisticsCount: 38699
  },
  {
    title: '参考人数',
    key: 'participateQuantity',
    icon: require('@/assets/console/count_3.png'),
    shadow: '0px 4px 3px rgba(197, 0, 0, 0.16)',
    shadowHover: '0px 4px 3px rgba(197, 0, 0, 0.26)',
    route: 'newExaminee',
    statisticsCount: 9687
  },
  {
    title: '专业总数',
    key: 'postQuantity',
    icon: require('@/assets/console/count_4.png'),
    shadow: '0px 4px 3px rgba(0, 197, 72, 0.16)',
    shadowHover: '0px 4px 3px rgba(0, 197, 72, 0.26)',
    route: 'post',
    statisticsCount: 869
  },
  {
    title: '学生总数',
    key: 'internalQuantity',
    icon: require('@/assets/console/count_5.png'),
    shadow: '0px 4px 3px rgba(197, 143, 0, 0.16)',
    shadowHover: '0px 4px 3px rgba(197, 143, 0, 0.26)',
    route: 'staffList',
    statisticsCount: 9688
  }
]
const quickList = [
  {
    title: '创建考试',
    icon: require('@/assets/console/quick_1.png'),
    shadow: '0px 4px 3px rgba(0, 112, 197, 0.16)',
    shadowHover: '0px 4px 3px rgba(0, 112, 197, 0.26)',
    route: 'newPaper'
  },
  {
    title: '创建试卷',
    icon: require('@/assets/console/quick_2.png'),
    shadow: '0px 4px 3px rgba(0, 197, 72, 0.16)',
    shadowHover: '0px 4px 3px rgba(0, 197, 72, 0.26)',
    route: 'paperBank'
  },
  {
    title: '添加人员',
    icon: require('@/assets/console/quick_3.png'),
    shadow: '0px 4px 3px rgba(197, 0, 0, 0.16)',
    shadowHover: '0px 4px 3px rgba(197, 0, 0, 0.26)',
    route: 'staffList'
  },
  {
    title: '添加专业',
    icon: require('@/assets/console/quick_4.png'),
    shadow: '0px 4px 3px rgba(197, 143, 0, 0.16)',
    shadowHover: '0px 4px 3px rgba(197, 143, 0, 0.26)',
    route: 'post'
  },
  {
    title: '添加题目',
    icon: require('@/assets/console/quick_5.png'),
    shadow: '0px 4px 3px rgba(197, 0, 158, 0.16)',
    shadowHover: '0px 4px 3px rgba(197, 0, 158, 0.26)',
    route: 'questionList'
  },
  {
    title: '考试考生',
    icon: require('@/assets/console/quick_6.png'),
    shadow: '0px 4px 3px rgba(0, 112, 197, 0.16)',
    shadowHover: '0px 4px 3px rgba(0, 112, 197, 0.26)',
    route: 'newExaminee'
  },
  {
    title: '查看课程',
    icon: require('@/assets/console/quick_7.png'),
    shadow: '0px 4px 3px rgba(197, 0, 0, 0.16)',
    shadowHover: '0px 4px 3px rgba(197, 0, 0, 0.26)',
    route: 'courseList'
  }
]
export { options, countList, quickList }
