<template>
  <div class="console-main yt-flex-layout">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item to="/home">首页</el-breadcrumb-item>
        <el-breadcrumb-item>控制台</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div
      class="console_header yt-flex-layout pLeftAndRight"
      :style="{ height: 360 * globalHeightRatio + 'px' }"
      v-loading="loading"
      element-loading-text="加载中..."
    >
      <div class="left_info bShadow yt-flex-layout">
        <div class="up_tip yt-flex-layout bShadow" :style="{ height: 93 * globalHeightRatio + 'px' }">
          <div class="first yt-flex-layout"><span>您好！欢迎使用源图数字化人才管理平台。</span><span>学校版控制台</span></div>
          <div class="second yt-flex-layout">用户：{{ authD ? authD.realName : '罗杰斯' }}</div>
        </div>
        <div class="down_statistics bShadow">
          <div
            @mouseenter="hoverRowLeft = cIndex"
            @mouseleave="hoverRowLeft = -1"
            class="row_one yt-flex-layout"
            v-for="(c, cIndex) in countList"
            @click="toPath(c)"
            :key="cIndex"
          >
            <img
              class="svg"
              :src="c.icon"
              :style="{
                boxShadow: hoverRowLeft === cIndex ? c.shadowHover : c.shadow
              }"
              style="margin-right: 16px;"
              width="65"
              height="65"
              alt=""
            />
            <div class="yt-flex-layout yt-flexColumns" style="height: 100%;justify-content: space-between">
              <p class="first_p" style="font-size: 20px">{{ c.statisticsCount }}</p>
              <p class="second_p">{{ c.title }}</p>
            </div>
            <a class="a_href__style">查看>></a>
            <div class="hover_div bShadow" v-show="hoverRowLeft === 0 && c.children">
              <div
                class="h_row__one"
                @click.stop="toPath({ route: r.route, params: rIndex ? 1 : 0 })"
                v-for="(r, rIndex) in c.children"
                :key="rIndex"
              >
                <img
                  class="svg"
                  @mouseenter="hoverRowChildren = rIndex"
                  @mouseleave="hoverRowChildren = -1"
                  :src="r.icon"
                  :style="{
                    boxShadow: hoverRowChildren === rIndex ? r.shadowHover : r.shadow
                  }"
                  style="margin-right: 16px;"
                  width="65"
                  height="65"
                  alt=""
                />
                <div class="yt-flex-layout yt-flexColumns" style="height: 100%;justify-content: space-between">
                  <p class="first_p__hover">{{ r.statisticsCount }}</p>
                  <p class="second_p__hover">{{ r.title }}</p>
                </div>
                <a>查看>></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right_quick bShadow">
        <div class="quick_title yt-flex-layout">快捷功能区</div>
        <div class="quick_style">
          <div
            @mouseenter="hoverRow = qIndex"
            @mouseleave="hoverRow = -1"
            class="quick_flex yt-flex-layout"
            :style="qIndex === hoverRow ? 'color: #448bff;' : ''"
            @click="toPath(q)"
            v-for="(q, qIndex) in quickList"
            :key="qIndex"
          >
            <img
              class="svg"
              :src="q.icon"
              :style="{
                boxShadow: qIndex === hoverRow ? q.shadowHover : q.shadow
              }"
              width="50"
              height="50"
              alt=""
            />
            <p style="margin-top: 5px">{{ q.title }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="pLeftAndRight" v-loading="examLoading" element-loading-text="加载中...">
      <ExamNowing ref="examData" />
    </div>

    <div class="pLeftAndRight" v-loading="chartLoading" element-loading-text="加载中...">
      <Echarts ref="myEchart" />
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import { countList, quickList } from './commonJS'
import Echarts from '@components/manage/console/Echarts'
import ExamNowing from '@components/manage/console/ExamNowing'
import newExamManageApi from '@api/newExamManage'
import { mapState } from 'vuex'
export default {
  name: 'ConsoleTag',
  components: { ExamNowing, Echarts, YTIcon },
  data() {
    return {
      loading: false,
      chartLoading: false,
      examLoading: false,
      globalHeightRatio: 1,
      hoverRowLeft: -1,
      hoverRowChildren: -1,
      hoverRow: -1,
      countList: countList,
      quickList: quickList
    }
  },
  computed: {
    ...mapState({
      authD: state => state.user.info
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.listener, true)
  },
  created() {
    this.examLoading = true
  },
  mounted() {
    this.getList()
    if (document.body.clientWidth / 1440 >= 1) {
      // 获取尺寸比例
      this.globalHeightRatio = Math.round((document.body.clientWidth / 1440) * 100) / 100
    }
    window.addEventListener('resize', this.listener, true)
  },
  methods: {
    listener() {
      let v = setTimeout(() => {
        this.$refs['myEchart'] ? this.$refs['myEchart'].reStart() : false
        let defaultSize = 1440
        let clientWidth = document.body.clientWidth
        if (clientWidth / defaultSize < 1) {
          this.globalHeightRatio = 1
        } else {
          this.globalHeightRatio = Math.round((clientWidth / defaultSize) * 100) / 100
        }
        clearTimeout(v)
      }, 800)
    },
    getList() {
      // 数据统计
      this.statistics()
      // 获取echarts图的数据
      this.chartData()
      // 正在进行中的考试数据
      newExamManageApi
        .examNowing()
        .then(res => {
          this.$refs['examData'].getData(
            (res.res = res.res.filter((r, index) => {
              return index < 6
            }))
          )
        })
        .finally(() => {
          this.examLoading = false
        })
    },
    chartData(val) {
      this.chartLoading = true
      const time = { endTime: new Date(), startTime: new Date() - 3600 * 1000 * 24 * 7 }
      newExamManageApi.examDataAnalysis(val ? val : time).then(res => {
        this.$refs['myEchart'].getData(res.res)
      })
    },
    statistics() {
      newExamManageApi.statisticsNUm().then(res => {
        this.countList.forEach(c => {
          c.statisticsCount = res.res[c.key]
          if (c.children) {
            c.children.forEach(ch => {
              ch.statisticsCount = res.res[ch.key]
            })
          }
        })
      })
    },
    toPath(row) {
      this.loading = true
      this.examLoading = true
      if (row.route) {
        this.$router.push({
          name: row.route,
          params:
            row.params === undefined
              ? null
              : {
                  params: row.params
                }
        })
      }
      this.loading = false
      this.examLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
@globalHeight: 1;
p,
span,
a,
svg {
  white-space: nowrap;
}
.console-main {
  .font(14px, Regular, #000);
  min-width: 1060px;
  flex-direction: column;
  background: #f3f4f7;
  .bShadow {
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  }
  .bread_style {
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    align-items: center;
    height: 44px;
    background: #fff;
  }
  .pLeftAndRight {
    padding: 0 10px;
  }
  .svg {
    border-radius: 50%;
  }
  .console_header {
    height: calc(360px * @globalHeight);
    margin-bottom: 20px;
    .left_info {
      flex-direction: column;
      margin-right: 10px;
      width: calc(100% - 396px);
      .up_tip {
        color: #fff;
        align-items: center;
        padding: 0 20px;
        margin-bottom: 15px;
        height: 93px;
        background: url('../../../assets/console/header_1.png') no-repeat;
        background-size: 100% 100%;
      }
      span {
        text-align: left;
      }
      .first {
        letter-spacing: 3px;
        font-size: 14px;
        width: 75%;
        flex-direction: column;
        span:first-child {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
      .second {
        font-size: 12px;
        padding: 20px 0;
        flex: auto;
        height: 100%;
        align-items: flex-end;
        justify-content: flex-end;
      }
      .down_statistics {
        flex: auto;
        align-items: center;
        background: #fff;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 0 0 0 48px;
        .row_one {
          position: relative;
          cursor: pointer;
          align-items: center;
          height: 65px;
          margin-right: 88px;
          p {
            .font(14px, bold, #000);
            text-align: left;
          }
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            .second_p,
            .a_href__style {
              color: #448bff;
            }
          }
          .hover_div {
            padding: 20px;
            display: grid;
            z-index: 999;
            grid-template-columns: 1fr 1fr;
            column-gap: 21px;
            background: #fff;
            position: absolute;
            top: 30px;
            left: 145px;
            .h_row__one {
              display: flex;
              align-items: center;
              &:hover {
                .second_p__hover:last-child,
                a {
                  color: #448bff;
                }
              }
            }
          }
        }
        .a_href__style,
        a {
          margin-left: 10px;
          .font(12px, Regualr, #666);
        }
      }
    }
    .right_quick {
      background: #fff;
      min-width: 386px;
      .quick_title {
        padding-left: 12px;
        align-items: center;
        height: 44px;
      }
      .quick_style {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        height: calc(100% - 40px);
        .quick_flex {
          cursor: pointer;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
@media screen and (max-width: 1470px) {
  .down_statistics {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
</style>
